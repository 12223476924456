import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
// import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },
  {
    exact: true,
    path: "/forget",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Forget")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/ResetPassword")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Verifyotp")),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Auth/ChangePassword")),
  },
  // {
  //   exact: true,
  //   path: "/",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/Home")),
  // },
  {
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/DashdoardHome")),
  },
  {
    exact: true,
    path: "/exchange",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Exchange")),
  },
  {
    exact: true,
    path: "/exchange-first",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/ExchangeMain")),
  },
  {
    exact: true,
    path: "/direct",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Direct")),
  },
  {
    exact: true,
    path: "/direct-plan",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/DirectFirst")),
  },
  {
    exact: true,
    path: "/intra",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Intra")),
  },
  {
    exact: true,
    path: "/triangular",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Triangular")),
  },
  {
    exact: true,
    path: "/loop",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Loop")),
  },
  {
    exact: true,
    path: "/transactions-history",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Transection")),
  },
  {
    exact: true,
    path: "/subscription",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Subscription")),
  },
  {
    exact: true,
    path: "/invite",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Invite")),
  },
  {
    exact: true,
    path: "/invite-view",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/InviteView")),
  },
  {
    exact: true,
    path: "/invite-edit",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/InviteEdit")),
  },
  {
    exact: true,
    path: "/setting",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Setting/BotSetting")),
  },
  {
    exact: true,
    path: "/my-wallet",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Wallet/Mywallet")),
  },
  {
    exact: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Profile")),
  },
  {
    exact: true,
    path: "/edit-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/EditProfile")),
  },
  {
    exact: true,
    path: "/notification",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static/Notification")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static/Privacy")),
  },
  {
    exact: true,
    path: "/FAQ",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static/FaqSection")),
  },
  {
    exact: true,
    path: "/term-and-condition",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static/Terms")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
